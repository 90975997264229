import React from 'react';
import MainLayout from 'src/layouts/Main';
import BlogImageCard from 'components/BlogImageCard';
import { graphql } from 'gatsby';

export default ({ data }) => {
  const posts = data.allBlogJson.edges;
  const pageContent = data.allPageContentJson.edges[0].node;
  const cards = posts.map((post, i) => {
    console.log(post.node);
    return (
      <BlogImageCard
        key={i}
        title={post.node.title}
        description={post.node.description}
        prefix='/blog'
        link={`${post.node.fields.slug}`}
        width='900'
        height='250'
        imgURL={
          `/images/${post.node.image}` ||
          '/images/trinity.jpg'
        }
      />
    );
  });
  return (
    <MainLayout>
      <div>{pageContent.headline}</div>
      <div className='card-deck blog-cards'>{cards}</div>
    </MainLayout>
  );
};

export const pageQuery = graphql`
  query {
    allPageContentJson(filter: { templateKey: { eq: "blog" } }) {
      edges {
        node {
          headline
        }
      }
    }
    allBlogJson(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          date
          body
          description
          image
          fields {
            slug
          }
        }
      }
    }
  }
`;

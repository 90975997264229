import React from 'react';
import { Card, CardTitle, CardText, CardImg, Button } from 'reactstrap';
import {} from 'gatsby';
import { FaGithub } from 'react-icons/fa';

const BlogImageCard = props => {
  return (
    <div style={{ width: '350px', color: 'black' }}>
      <Card>
        <CardImg width='10%' src={props.imgURL} alt={props.title} />
        <CardTitle>{props.title}</CardTitle>
        <CardText>{props.description}</CardText>
        <Button color='primary' size='sm' href={props.prefix + props.link}>
          Small Button
        </Button>{' '}
      </Card>
    </div>
  );
};

export default BlogImageCard;
